body {
  font-family: Helvetica, sans-serif;
  font-size: 12px;
}

.pace-calculator {
  margin: 100px auto 0;
  width: 530px;
}

.pace-calculator-container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
}

.entry-unit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  text-align: right;
  padding-right: 10px;
  background-color: #303030;
  color: white;
}

.entry-row {
  display: flex;
}

.entry-fields {
  display: inline-block;
  width: 180px;
  padding: 10px 20px;
}

.entry-action {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-secondary {
  background-color: #f5f5f5;
}

.background-secondary-contrast {
  background-color: #fbfbfb1f;
}

.multiple-input-container {
  display: -webkit-flex;
  display: flex;
  margin: auto;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 180px;
}

.multiple-input-container > input {
  margin-right: 10px;
  width: 100%;
}

.single-input {
  display: flex;
  flex-direction: column;
}

.single-input label {
  margin-bottom: 2px;
  font-size: 12px;
}

.pace-calculator-header {
  padding: 8px;
  margin: 0;
  font-size: inherit;
  background-color: #303030;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  height: 22px;
}

@media only screen and (max-width: 768px) {
  #pace-calculator {
    margin-top: 0;
    width: 100%;
  }

  .entry-unit {
    padding-right: 0;
    width: 60px;
  }

  .entry-action {
    flex-grow: 1;
    width: auto;
  }

  .entry-action > button {
    width: 75px;
    white-space: normal;
    height: 40px;
  }

  .entry-fields {
    padding: 10px 5px;
    width: auto;
    flex-grow: 1;
  }

  .pace-calculator-container {
    border-radius: 0;
    border: none;
  }
}
