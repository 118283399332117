.main-wrapper {
  text-align: center;
  width: 530px;
  margin: 0 auto;
}

.well {
  margin: 20px 0px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  padding: 10px;
  text-align: left;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .main-wrapper {
    width: 100%;
  }
}

input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  font-size: 16px;
}
